var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"table-actions-row"},[_c('v-col',{staticClass:"pl-0",attrs:{"sm":"4","cols":"12"}},[_c('breadcrumbs',{attrs:{"items":_vm.crumbs}})],1),_c('v-col',{staticClass:"d-flex justify-end align-center",attrs:{"sm":"8"}})],1),_c('div',{staticClass:"page-container"},[_c('v-card',{staticClass:"dealer-order-statistics-card card d-flex",attrs:{"min-height":"712.5px"}},[_c('localization',{attrs:{"language":'ru-RU'}},[_c('intl',{attrs:{"locale":'ru'}},[_c('Grid',{ref:"grid",staticClass:"dealer-product-orders-statistics",attrs:{"resizable":true,"sortable":true,"filterable":true,"pageable":_vm.gridPageable,"filter":_vm.filter,"take":_vm.take,"skip":_vm.skip,"sort":_vm.sort,"page-size":_vm.pageSize,"total":_vm.total,"data-items":_vm.dataResult,"columns":_vm.columns,"detail":_vm.detailTemplate,"expand-field":'expanded'},on:{"datastatechange":_vm.dataStateChange,"expandchange":_vm.expandChange,"sortchange":_vm.sortChange},scopedSlots:_vm._u([{key:"detail",fn:function(ref){
var dataItem = ref.props.dataItem;
return [_c('div',{staticClass:"detail-wrapper"},[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{key:"client",attrs:{"href":"#client"}},[_vm._v("Контактное лицо")]),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{key:"client",attrs:{"id":"client"}},[_c('v-container',{staticClass:"ml-0 grey lighten-5"},[_c('div',[_c('table',{staticClass:"table table-striped m-table"},[_c('tbody',[_c('tr',{staticStyle:{"visibility":"hidden"}},[_c('td',{attrs:{"width":"200px"}}),_c('td')]),_c('tr',[_c('td',[_vm._v(" ФИО: ")]),_c('td',[_c('p',[_vm._v(" "+_vm._s(dataItem .dealer .contact_person_name)+" ")])])]),_c('tr',[_c('td',{attrs:{"width":"200px"}},[_vm._v(" E-mail: ")]),_c('td',[_c('p',[_vm._v(" "+_vm._s(dataItem .dealer .contact_person_email)+" ")])])]),_c('tr',[_c('td',[_vm._v(" Адрес: ")]),_c('td',[_c('p',[_vm._v(" "+_vm._s(dataItem .dealer .contact_person_address)+" ")])])]),_c('tr',[_c('td',[_vm._v(" Телефон: ")]),_c('td',[_c('p',[_vm._v(" "+_vm._s(dataItem .dealer .contact_person_phone)+" ")])])])])])])])],1)],1)],1)],1)]}},{key:"status_filter",fn:function(ref){
var methods = ref.methods;
var props = ref.props;
return [_c('dropdownlist',{staticClass:"categories-filter",attrs:{"data-items":_vm.OrderStatuses,"text-field":'name',"data-item-key":'key',"default-item":{ id: null, name: 'Все' }},on:{"change":_vm.selectStatus}})]}},{key:"date_filter",fn:function(ref){
var methods = ref.methods;
var props = ref.props;
return [_c('div',{staticClass:"d-flex align-end"},[_c('datepicker',{attrs:{"default-show":_vm.defaultShow,"start-date-input-settings":_vm.startDateInputSettings,"end-date-input-settings":_vm.endDateInputSettings,"value":_vm.value},on:{"change":_vm.dateChangeHandler}}),_c('button',{staticClass:"k-button k-button-icon k-clear-button-visible",attrs:{"title":"Clear","type":"button"},on:{"click":_vm.clearDates}},[_c('span',{staticClass:"k-icon k-i-filter-clear"})])],1)]}},{key:"status",fn:function(ref){
var dataItem = ref.props.dataItem;
return [_c('td',[_vm._v(" "+_vm._s(_vm.getProductOrderStatusNameByKey( dataItem.product_order_status ))+" ")])]}}])})],1)],1),_c('v-row',{staticClass:"mt-3 mb-2"},[_c('v-col',{staticClass:"pl-0 d-flex justify-end",attrs:{"cols":"12"}},[_c('CButton',{staticClass:"export-excel-btn",attrs:{"color":"success"},on:{"click":_vm.downloadReport}},[_c('i',{staticClass:"mr-2 fa fa-save"}),_c('span',[_vm._v("Экспорт в Excel")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }