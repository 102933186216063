export default {
    data: () => ({
        grid: "",
        setMinWidth: false,
        minGridWidth: 1200,
        gridCurrent: 0,
        columns: [],
        columnsData: []
    }),
    mounted() {
        const that = this;

        this.grid = document.querySelector(".k-grid");
        window.addEventListener("resize", this.handleResize);
        this.columns.map(item => {
            return (that.minGridWidth += (item.minWidth ? item.minWidth : 0));
        });

        this.gridCurrent = this.grid.offsetWidth;
        this.setMinWidth = this.grid.offsetWidth < this.minGridWidth;
        // this.defineColumns();
        this.handleResize();
    },
    methods: {
        defineColumns() {
            const that = this;

            this.columns = this.columns.map((column, index) => {
                column.width = that.setWidth(column.minWidth, index);

                return column;
            });

        },
        handleResize() {

            if (this.grid.offsetWidth < this.minGridWidth && !this.setMinWidth) {
                this.setMinWidth = true;
            } else if (this.grid.offsetWidth > this.minGridWidth) {
                this.gridCurrent = this.grid.offsetWidth;
                this.setMinWidth = false;
            }

            this.defineColumns();
            this.setLastColumnSize();
        },
        setWidth(minWidth, index) {
            let width;

            width = this.setMinWidth
                    ? minWidth
                    : minWidth + (this.gridCurrent - this.minGridWidth) / this.columns.length;

            return width;
        },
        setLastColumnSize() {
            const lastIndex = this.columns.length - 1;
            const lastColumnMinWidth = this.columns[lastIndex].minWidth;
            const lastColumn= this.columns[lastIndex];

            const width = this.columns.reduce((accumulator, column, index) => {
                if (index < lastIndex) {
                    return accumulator + column.width;
                }

                return accumulator;
            }, 0);

            const remainingWidth = this.grid.offsetWidth - width;

            if (remainingWidth >= lastColumnMinWidth) {
                lastColumn.width = remainingWidth - 20;
            }
        }
    }
}