<template>
    <div>
        <v-row class="table-actions-row">
            <v-col sm="4" cols="12" class="pl-0">
                <breadcrumbs :items="crumbs"></breadcrumbs>
            </v-col>
            <v-col sm="8" class="d-flex justify-end align-center"></v-col>
        </v-row>
        <div class="page-container">
            <v-card
                class="dealer-order-statistics-card card d-flex"
                min-height="712.5px"
            >
                <localization :language="'ru-RU'">
                    <intl :locale="'ru'">
                        <Grid
                            ref="grid"
                            class="dealer-product-orders-statistics"
                            :resizable="true"
                            :sortable="true"
                            :filterable="true"
                            :pageable="gridPageable"
                            :filter="filter"
                            @datastatechange="dataStateChange"
                            :take="take"
                            :skip="skip"
                            :sort="sort"
                            :page-size="pageSize"
                            :total="total"
                            :data-items="dataResult"
                            :columns="columns"
                            :detail="detailTemplate"
                            :expand-field="'expanded'"
                            @expandchange="expandChange"
                            @sortchange="sortChange"
                        >
                            <template v-slot:detail="{ props: { dataItem } }">
                                <div class="detail-wrapper">
                                    <v-tabs v-model="tab">
                                        <v-tab key="client" href="#client"
                                            >Контактное лицо</v-tab
                                        >
                                        <v-tabs-items v-model="tab">
                                            <v-tab-item
                                                key="client"
                                                id="client"
                                            >
                                                <v-container
                                                    class="ml-0 grey lighten-5"
                                                >
                                                    <div>
                                                        <table
                                                            class="table table-striped m-table"
                                                        >
                                                            <tbody>
                                                                <tr
                                                                    style="
                                                                        visibility: hidden;
                                                                    "
                                                                >
                                                                    <td
                                                                        width="200px"
                                                                    ></td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        ФИО:
                                                                    </td>
                                                                    <td>
                                                                        <p>
                                                                            {{
                                                                                dataItem
                                                                                    .dealer
                                                                                    .contact_person_name
                                                                            }}
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td
                                                                        width="200px"
                                                                    >
                                                                        E-mail:
                                                                    </td>
                                                                    <td>
                                                                        <p>
                                                                            {{
                                                                                dataItem
                                                                                    .dealer
                                                                                    .contact_person_email
                                                                            }}
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Адрес:
                                                                    </td>
                                                                    <td>
                                                                        <p>
                                                                            {{
                                                                                dataItem
                                                                                    .dealer
                                                                                    .contact_person_address
                                                                            }}
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Телефон:
                                                                    </td>
                                                                    <td>
                                                                        <p>
                                                                            {{
                                                                                dataItem
                                                                                    .dealer
                                                                                    .contact_person_phone
                                                                            }}
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </v-container>
                                            </v-tab-item>
                                        </v-tabs-items>
                                    </v-tabs>
                                </div>
                            </template>

                            <template v-slot:status_filter="{ methods, props }">
                                <dropdownlist
                                    class="categories-filter"
                                    :data-items="OrderStatuses"
                                    :text-field="'name'"
                                    :data-item-key="'key'"
                                    :default-item="{ id: null, name: 'Все' }"
                                    @change="selectStatus"
                                />
                            </template>
                            <template v-slot:date_filter="{ methods, props }">
                                <div class="d-flex align-end">
                                    <datepicker
                                        :default-show="defaultShow"
                                        :start-date-input-settings="
                                            startDateInputSettings
                                        "
                                        :end-date-input-settings="
                                            endDateInputSettings
                                        "
                                        :value="value"
                                        @change="dateChangeHandler"
                                    />
                                    <button
                                        @click="clearDates"
                                        title="Clear"
                                        type="button"
                                        class="k-button k-button-icon k-clear-button-visible"
                                    >
                                        <span class="k-icon k-i-filter-clear"></span></button>
                                </div>
                            </template>
                            <template v-slot:status="{ props: { dataItem } }">
                                <td>
                                    {{
                                        getProductOrderStatusNameByKey(
                                            dataItem.product_order_status
                                        )
                                    }}
                                </td>
                            </template>
                        </Grid>
                    </intl>
                </localization>
                <v-row class="mt-3 mb-2">
                    <v-col cols="12" class="pl-0 d-flex justify-end">
                        <CButton
                            @click="downloadReport"
                            class="export-excel-btn"
                            color="success"
                        >
                            <i class="mr-2 fa fa-save"></i
                            ><span>Экспорт в Excel</span>
                        </CButton>
                    </v-col>
                </v-row>
            </v-card>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import TableMixin from '@/views/grid_elements/mixins/TableMixin';
    import ResizableTableMixin from '@/views/grid_elements/mixins/ResizableTableMixin';
    import { Grid } from '@progress/kendo-vue-grid';
    import {
        IntlProvider,
        LocalizationProvider,
    } from '@progress/kendo-vue-intl';
    import Breadcrumbs from '@/views/components/breadcrumbs';
    import '@/views/grid_elements/translations';
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import OrderStatuses from '@/config/order_statuses';
    import api from '@/api';
    import { toDataSourceRequestString } from '@progress/kendo-data-query';
    import { DateRangePicker } from '@progress/kendo-vue-dateinputs';

    export default {
        name: 'DealerProductOrdersStatistics',
        components: {
            Grid: Grid,
            breadcrumbs: Breadcrumbs,
            localization: LocalizationProvider,
            intl: IntlProvider,
            dropdownlist: DropDownList,
            datepicker: DateRangePicker,
        },
        mixins: [TableMixin, ResizableTableMixin],
        async mounted() {
            this.getData();
            await this.fetchDealerListShort();
        },
        data() {
            return {
                skip: 0,
                take: 10,
                pageSize: 10,
                detailTemplate: 'detail',
                total: 7,
                value: {
                    start: null,
                    end: null,
                },
                defaultShow: false,
                startDateInputSettings: {
                    label: 'От',
                    format: 'dd.MM.yyyy',
                },
                endDateInputSettings: {
                    format: 'dd.MM.yyyy',
                    label: 'До',
                },
                expandedItems: [],
                gridPageable: { pageSizes: true },
                tab: null,
                sort: [{ field: 'product_order_date', dir: 'desc' }],
                columns: [
                    {
                        field: 'dealer_unique_id',
                        title: 'КССС',
                        minWidth: 160,
                        width: '160px',
                        type: 'text',
                        filter: 'text',
                    },
                    {
                        field: 'dealer_name',
                        title: 'Дилер',
                        minWidth: 250,
                        width: '250px',
                        type: 'text',
                    },
                    {
                        field: 'product_order_number',
                        title: 'Номер заказа',
                        minWidth: 200,
                        width: '200px',
                        type: 'text',
                        filter: 'text',
                    },
                    {
                        field: 'product_order_date',
                        title: 'Дата заказа',
                        minWidth: 400,
                        width: '400px',
                        type: 'date',
                        filter: 'date',
                        format: '{0:d}',
                        filterCell: 'date_filter',
                    },
                    {
                        field: 'product_order_item_position_name',
                        title: 'Позиция',
                        minWidth: 280,
                        width: '280px',
                        type: 'text',
                        filter: 'text',
                    },
                    {
                        field: 'product_order_item_price',
                        title: 'Цена',
                        minWidth: 200,
                        width: '200px',
                        type: 'numeric',
                        filter: 'numeric',
                    },
                    {
                        field: 'product_order_item_position_quantity',
                        title: 'Количество в пачке',
                        minWidth: 150,
                        width: '150px',
                        type: 'numeric',
                        filter: 'numeric',
                    },
                    {
                        field: 'product_order_item_quantity',
                        title: 'Количество',
                        minWidth: 200,
                        width: '200px',
                        type: 'numeric',
                        filter: 'numeric',
                    },
                    {
                        field: 'amount',
                        title: 'Общая сумма заказа',
                        minWidth: 200,
                        width: '200px',
                        type: 'numeric',
                        filter: 'numeric',
                    },
                    {
                        field: 'product_order_status',
                        title: 'Статус',
                        minWidth: 200,
                        width: '200px',
                        cell: 'status',
                        filterCell: 'status_filter',
                    },
                    {
                        title: 'Контактное лицо',
                        minWidth: 480,
                        width: '480px',
                        children: [
                            {
                                field: 'dealer_contact_person_name',
                                title: 'Контакты (ФИО)',
                                minWidth: 250,
                                width: '250px',
                                type: 'text',
                                filter: 'text',
                            },
                            {
                                field: 'dealer_contact_person_email',
                                title: 'Email',
                                minWidth: 250,
                                width: '250px',
                                type: 'text',
                                filter: 'text',
                            },
                            {
                                field: 'product_order_delivery_address',
                                title: 'Адрес склада',
                                minWidth: 300,
                                width: '300px',
                                type: 'text',
                                filter: 'text',
                            },
                            {
                                field: 'dealer_contact_person_phone',
                                title: 'Контактный телефон',
                                minWidth: 250,
                                width: '250px',
                                type: 'text',
                                filter: 'text',
                            },
                        ],
                    },
                    {
                        title: 'E-mail получателя',
                        minWidth: 250,
                        width: '250px',
                        field: 'recipient_email',
                        filter: 'text',
                    },
                    {
                        title: 'Телефон получателя',
                        minWidth: 250,
                        width: '250px',
                        field: 'recipient_phone',
                        filter: 'text',
                    }
                ],
                OrderStatuses,
            };
        },
        methods: {
            ...mapActions('agent_user_orders', ['fetchDealerListShort']),
            fetchData: api.agent_user.getDealerProductOrderStatistics,
            selectStatus({ value }) {
                if (value.key) {
                    this.setFilter('product_order_status', 'eq', value.key);
                } else {
                    this.removeFilter('product_order_status');
                }

                this.updateTable(true);
            },
            prepareFetchParams() {
                return [
                    this.page,
                    this.take,
                    toDataSourceRequestString({
                        filter: this.filter,
                        sort: this.sort,
                    }),
                ];
            },
            selectDealer({ value }) {
                if (value.id) {
                    this.setFilter('dealer_id', 'eq', value.id);
                } else {
                    this.removeFilter('dealer_id');
                }

                this.updateTable(true);
            },
            dateChangeHandler({ value }) {
                this.value.start = value.start
                this.value.end = value.end
                if (value.start || value.end) {
                    const dateStart = new Date(value.start).toLocaleDateString(
                        'ru-RU'
                    );
                    const dateEnd = new Date(value.end).toLocaleDateString(
                        'ru-RU'
                    );
                    if (dateEnd === '01.01.1970') {
                        this.setFilter('date', 'gte', dateStart);
                    } else if (dateStart === dateEnd) {
                        this.removeFilter('date');
                        this.setFilter('date', 'gtedatetime', dateStart);
                    } else {
                        this.removeFilter('date');
                        const obj = [
                            {
                                field: 'date',
                                operator: 'gte',
                                value: dateStart + 'T00:00:00',
                            },
                            {
                                field: 'date',
                                operator: 'lte',
                                value: dateEnd + 'T23:59:59',
                            },
                        ];
                        this.setMultipleFilter('date', obj);
                    }
                } else {
                    this.removeFilter('dealer_id');
                }
                this.updateTable(true);
            },
            clearDates() {
                this.value.start = null
                this.value.end = null
                this.clearFilters()
                this.updateTable(true);
            },
            async downloadReport() {
                try {
                    const response =
                        await api.agent_user.getDealerProductOrderStatisticsReport(
                            toDataSourceRequestString({
                                filter: this.filter,
                                sort: this.sort,
                            })
                        );

                    const fileURL = window.URL.createObjectURL(
                        new Blob([response.data])
                    );
                    const fileLink = document.createElement('a');

                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', `Заказы.xlsx`);
                    document.body.appendChild(fileLink);

                    fileLink.click();
                } catch (e) {
                    console.warn(e);
                }
            },
        },
        computed: {
            ...mapGetters('agent_user_orders', ['dealersListShort']),
            ...mapGetters('orders', ['getProductOrderStatusNameByKey']),
        },
    };
</script>

<style lang="sass" scoped>
    .page-container
      height: 100%

    .dealer-order-statistics-card
      padding: 15px 15px 0 15px
      overflow-x: scroll

    .dealer-product-orders-statistics
      min-height: 750px
</style>
