<template>
  <v-card>
    <v-breadcrumbs
        v-bind="{...$props, ...$attrs}"
        class="top-breadcrumbs"
    >
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
            :href="item.href"
            :disabled="item.disabled"
        >
        <span v-if="item.disabled">
          <template v-if="item.iconClasses">
            <i class="v-breadcrumbs__item--icon" :class="item.iconClasses"></i>
          </template>
          <template v-else>
          {{ item.text }}
          </template>
        </span>
          <span v-else>
          <router-link :to="{path: item.to}">
            {{ item.text }}
          </router-link>
        </span>
        </v-breadcrumbs-item>
      </template>
      <template v-slot:divider>
        <v-icon>mdi-arrow-right-bold</v-icon>
      </template>
    </v-breadcrumbs>
  </v-card>
</template>

<script>
export default {
  name: "breadcrumbs",
  props: {
    items: {
      type: Array,
      default: []
    }
  }
}
</script>

<style scoped>

</style>